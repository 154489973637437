import { useRoutes } from "react-router";
import { Navigate } from 'react-router-dom';

//Components
import Main from "../main/main";
import Portfolio from "../portfolio/portfolio";
import Kitchen from "../portfolio/kitchen";
import Contact from "../contact/contact";
import Bath from "../portfolio/bath";

export default function Pages(props) {
    let routes = useRoutes([
      { path: "/*", element: <Navigate to="/main" /> },
      {path: "/main", element: <Main/>},
      {path: "/portfolio", element: <Portfolio/>},
      {path: "/portfolio-kitchen", element: <Kitchen/>},
      {path: "/contact", element: <Contact/>},
      {path: "/portfolio-bath", element: <Bath/>},
    ]);
    return routes;
  };
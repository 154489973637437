import React from "react";
import './main.css'

//CSS Image
import Banner from '../../assets/landing/Banner.jpg';
import BannerMobile from '../../assets/landing/mainBannerMobile.jpg';

///Material
import Grid from '@mui/material/Grid';


export default function Main(props) {
    return (
        <>
            <Grid container justifyContent="center" className="mt-1" >
                <Grid className="position-relative">
                    <img src={Banner} className="w-92 hideOnMobile" />
                    <img src={BannerMobile} className="showOnlyOnMobile w-90-mobile"/>
                </Grid>
                <Grid className="position-font-banner">
                    <span className="font-banner">art &amp; creation</span>
                </Grid>
                <Grid className="position-font-banner-name">
                    <span className="font-banner-name">R&amp;O INSTALLATION</span>
                </Grid>
            </Grid>
            <Grid container justifyContent="center" className="mt-1">
                <Grid item md={12}>
                    <span className="title-aboutUs">About Us</span>
                </Grid>
            </Grid>
            <Grid container justifyContent="center" className="mt-1">
                <Grid item md={1}>
                    <Grid>&nbsp;</Grid>
                </Grid>
                <Grid item md={5}>
                    <p className="p-description">We are a company dedicated to the installation and remodeling of luxury kitchen cabinets for housing projects and houses..
                    </p>
                </Grid>
                <Grid item md={5}>
                    <p className="p-description">In R&amp;O Installation we make the classic design with a modern style, leaving your house 100% attractive.
                    </p>
                </Grid>
                <Grid item md={1}>
                    <Grid>&nbsp;</Grid>
                </Grid>
            </Grid>
        </>
    )
}
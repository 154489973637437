import React from "react";
import './commons.css';

//CSS Image
import LogoFooter from '../../assets/landing/LogoFooter.png';


///Material
import Grid from '@mui/material/Grid';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MailOutlineIcon from '@mui/icons-material/MailOutline';


export default function Footer(props) {
    return (
        <>
            <Grid className="bg-grey">
                <Grid container justifyContent="center" className="mt-1">
                    <Grid item md={12}>
                        <img src={LogoFooter} className="w-15 mt-1 w-30-mobile" />
                    </Grid>
                </Grid>
                <Grid Grid container justifyContent="center" className="mt-1">
                    <Grid item md={1} xs={2}>
                    <a href="https://www.instagram.com/roinstallation/" target="_blank"><InstagramIcon className="color-white" /></a>
                    </Grid>
                    <Grid item md={1} xs={2}>
                    <a href="https://www.facebook.com/RO-Installation-102128832414181" target="_blank"><FacebookIcon className="color-white" /></a>
                    </Grid>
                    <Grid item md={1} xs={2}>
                        <a href="https://api.whatsapp.com/send?phone=+1(407)459-5892"><WhatsAppIcon className="color-white" /></a>
                    </Grid>
                    <Grid item md={1} xs={2}>
                    <a href="mailto:Roinstallation@gmail.com" className="a-links color-white"><MailOutlineIcon /></a> 
                    </Grid>
                </Grid>
                <Grid Grid container justifyContent="center" className="mt-1">
                    <Grid item md={5}>
                        <p className="font-footer">2774 East Colonial #1131 Drive &#124;  Orlando, FL &#124; United States 32803</p>
                    </Grid>
                </Grid>
                <Grid Grid container justifyContent="center" className="mt-1">
                    <Grid item md={2} xs={2}>
                        <a href="/*" className="a-footer"><p className="font-footer-links">About Us</p></a>
                    </Grid>
                    <Grid item md={2} xs={2}>
                        <a href="/contact" className="a-footer"><p className="font-footer-links">Contact Us</p></a>
                    </Grid>
                    <Grid item md={2} xs={2}>
                        <a href="/portfolio" className="a-footer"><p className="font-footer-links">Portfolio</p></a>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}
import './App.css';
import Pages from './components/routesConfig/routes';
import { BrowserRouter as Router } from 'react-router-dom';

//Components
import Header from "./components/commons/header"
import Footer from "./components/commons/footer";


function App() {
  return (
    <div className="App">
      <Header />
      <Router>
        <Pages />
      </Router>
      <Footer />
    </div>
  );
}

export default App;

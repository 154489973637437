import React from "react";
import './contact.css'

//CSS Image
import MainImg from '../../assets/contact/contact.jpg';
import MainIgmMobile from '../../assets/contact/contactMobile.jpg';

///Material
import Grid from '@mui/material/Grid';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

export default function Contact(props) {
    return (
        <>
            <Grid container justifyContent="center" className="mt-3">
                <Grid item md={5} xs={12}>
                    <img src={MainImg} className="hideOnMobile" />
                    <img src={MainIgmMobile} className="showOnlyOnMobile w-90-mobile" />
                </Grid>
                <Grid item md={5} xs={12} className="bg-grey-contact">
                    <Grid container justifyContent="left">
                        <Grid md={8} xs={6}>
                            <span className="font-title-contact">Contact</span>
                        </Grid>
                        <Grid md={4}>
                            &nbsp;
                        </Grid>
                        <Grid item md={9} xs={12} className="font-contact-info">
                            <a href="tel:+1 (407) 459-5892" className="a-links">   <b>Phone.:</b> +1 (407) 459-5892. <LocalPhoneIcon /></a>&nbsp;<WhatsAppIcon />
                        </Grid>
                        <Grid item md={11} xs={12} className="font-contact-info">
                            <a href="mailto:Roinstallation@gmail.com" className="a-links"><b>Email:</b>Roinstallation@gmail.com. <MailOutlineIcon /></a>
                        </Grid>
                        <Grid Grid container justifyContent="center" className="mt-1">
                            <Grid item md={12} xs={12} className="font-followUs">
                                follow us
                            </Grid>
                            <Grid item md={2} xs={1}>
                                <a href="https://www.instagram.com/roinstallation/" target="_blank"><InstagramIcon className="color-white" /></a>
                            </Grid>
                            <Grid item md={2} xs={1}>
                                <a href="https://www.facebook.com/RO-Installation-102128832414181" target="_blank"><FacebookIcon className="color-white" /></a>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>

    )
}
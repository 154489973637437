import React from "react";
import './commons.css';

//CSS Image
import Logo2 from '../../assets/landing/Logo2.png';

//Material
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import MenuIcon from '@mui/icons-material/Menu';


export default function Header(props) {
    return (
        <>
            <Grid container justifyContent="center" className="mt-1 hideOnMobile">
                <Grid item md={3} xs={3}>
                    <img src={Logo2} className="w-50" />
                </Grid>
                <Grid item md={1}>
                    <Grid>&nbsp;</Grid>
                </Grid>
                <Grid item md={2}>
                    <Grid>&nbsp;</Grid>
                </Grid>
                <Grid item md={2}>
                    <a href="/*" className="a-menu">
                        <Grid className="font-menu">Home</Grid>
                    </a>
                </Grid>
                <Grid item md={2}>
                    <a href="/portfolio" className="a-menu">
                        <Grid className="font-menu">Portfolio</Grid>
                    </a>
                </Grid>
                <Grid item md={2}>
                    <a href="/contact" className="a-menu">
                        <Grid className="font-menu">Contact</Grid>
                    </a>
                </Grid>
            </Grid>
            <Divider />
            <Grid className="showOnlyOnMobile" container justifyContent="center">
                <Grid item xs={12}>
                    <img src={Logo2} className="w-50" />
                </Grid>
            </Grid>
            <Grid className="showOnlyOnMobile" container justifyContent="right">
                <Grid item xs={3}>
                    <PopupState variant="popover" popupId="demo-popup-menu">
                        {(popupState) => (
                            <React.Fragment >
                                <Button {...bindTrigger(popupState)}>
                                    <MenuIcon className="color-black-manu-phone" />
                                </Button>
                                <Menu {...bindMenu(popupState)} >
                                    <MenuItem onClick={popupState.close}>
                                        <a href="/*" className="a-menu">
                                            <Grid className="font-menu">Home</Grid>
                                        </a>
                                    </MenuItem>
                                    <MenuItem onClick={popupState.close}>
                                        <a href="/portfolio" className="a-menu">
                                            <Grid className="font-menu">Portfolio</Grid>
                                        </a>
                                    </MenuItem>
                                    <MenuItem onClick={popupState.close}>
                                        <a href="/contact" className="a-menu">
                                            <Grid className="font-menu">Contact</Grid>
                                        </a>
                                    </MenuItem>
                                </Menu>
                            </React.Fragment>
                        )}
                    </PopupState>
                </Grid>
            </Grid>
        </>
    )
}
import React from "react";
import './portfolio.css'

//CSS Image
import Galery1 from '../../assets/portfolio/Galery1.jpg';
import Galery2 from '../../assets/portfolio/Galery2.jpg';
import Galery3 from '../../assets/portfolio/Galery3.jpg';
import Galery4 from '../../assets/portfolio/Galery4.jpeg';
import Galery5 from '../../assets/portfolio/Galery5.jpeg';
import Galery6 from '../../assets/portfolio/Galery6.jpeg';
import Galery7 from '../../assets/portfolio/Galery7.jpeg';
import Galery8 from '../../assets/portfolio/Galery8.jpeg';
import Galery9 from '../../assets/portfolio/Galery9.jpeg';
import terminado1 from '../../assets/portfolio/terminado1.jpg';


//Material
import Grid from '@mui/material/Grid';


export default function Kitchen(props) {
    return (
        <>
            <Grid container justifyContent="center">
                <Grid item md={12} xs={12} className="m-1">
                    <span className="font-title">Kitchen</span>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item md={6} xs={12}>
                    <img src={Galery1} />
                </Grid>
                <Grid item md={6} xs={12}>
                    <img src={Galery2} />
                </Grid>
                <Grid item md={6} xs={12}>
                    <img src={Galery3} />
                </Grid>
                <Grid item md={6} xs={12}>
                    <img src={Galery4} />
                </Grid>
                <Grid item md={4}>
                    <img src={Galery5} />
                </Grid>
                <Grid item md={4}>
                    <img src={Galery6} />
                </Grid>
                <Grid item md={4}>
                    <img src={Galery7} />
                </Grid>
                <Grid>
                    <img src={Galery8} />
                </Grid>
                <Grid>
                    <img src={Galery9} />
                </Grid>
                <Grid>
                    <img src={terminado1} />
                </Grid>
            </Grid>
        </>

    )
}
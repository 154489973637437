import React from "react";
import './portfolio.css'

//CSS Image
import Kitchen from '../../assets/portfolio/cocinaModerna.jpg';
import Bath from '../../assets/portfolio/bath.jpg';


//Material
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';


export default function Portfolio(props) {
    return (
        <>
            <Grid container justifyContent="center">
                <Grid item md={12} xs={12} className="m-1">
                    <span className="font-title">Portfolio</span>
                </Grid>
                <Grid item md={5} xs={12}>
                    <img src={Kitchen} className="img-change hideOnMobile" />
                </Grid>
                <Grid item md={5} xs={12}>
                    <img src={Bath} className="img-change hideOnMobile" />
                </Grid>
            </Grid>
            <Grid container justifyContent="center" className="mt-1">
                <Grid item md={12} className="hideOnMobile">
                    <Grid className="font-kitchen">Kitchen</Grid>
                </Grid>
                <Grid item md={12} className="hideOnMobile">
                    <a href="/portfolio-kitchen"><Button variant="outlined" className="btn-sm-kitchen">See more</Button></a>
                </Grid>
                <Grid item md={12} className="hideOnMobile">
                    <Grid className="font-lr">Bath room</Grid>
                </Grid>
                <Grid item md={12} className="hideOnMobile">
                    <a href="/portfolio-bath"><Button variant="outlined" className="btn-sm-livingRoom">See more</Button></a>
                </Grid>


                <Stack direction="column" spacing={2} className="w-90 img-background-kitchen h-stack showOnlyOnMobile">
                    <Grid className="font-lr-mobile mt-40">Kitchen</Grid>
                    <a href="/portfolio-kitchen" className="text-decoration-none"><Button variant="outlined" className="btn-sm-bath">See more</Button></a>
                </Stack>
                <Stack direction="column" spacing={2} className="w-90 img-background-bath h-stack showOnlyOnMobile">
                    <Grid className="font-lr-mobile mt-40">Bath Room</Grid>
                    <a href="/portfolio-bath" className="text-decoration-none"><Button variant="outlined" className="btn-sm-bath">See more</Button></a>
                </Stack>
            </Grid>
        </>
    )
}
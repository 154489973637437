import React from "react";
import './portfolio.css'

//CSS Image
import Galery10 from '../../assets/portfolio/Galery10.png';
import Galery11 from '../../assets/portfolio/Galery11.jpg';
import Galery12 from '../../assets/portfolio/Galery12.jpg';


//Material
import Grid from '@mui/material/Grid';


export default function Bath(props) {
    return (
        <>
            <Grid container justifyContent="center">
                <Grid item md={12} xs={12} className="m-1">
                    <span className="font-title">Bath Room</span>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item md={6}>
                    <img src={Galery10} className="w-90" />
                </Grid>
                <Grid item md={6}>
                    <img src={Galery11} className="w-55" />
                </Grid>
                <Grid item md={6}>
                    <img src={Galery12} />
                </Grid>
            </Grid>
        </>

    )
}